<template>
  <Layout>
    <div class="app-pin-order-list">
      <a-page-header title="团单" @back="() => this.$router.back()">
        <template slot="backIcon">
          <a-icon v-if="pinId > 0" type="arrow-left" />
          <span v-else />
        </template>
      </a-page-header>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <span slot="img" slot-scope="img">
            <img :src="img" class="product-img" />
          </span>
          <span slot="avatarUrl" slot-scope="img">
            <img :src="img" class="product-img" />
          </span>
          <span slot="createdAt" slot-scope="time">{{
            time | formatDate
          }}</span>
          <span slot="num" slot-scope="num, record">
            <a @click="showUserList(record)">{{ num }}</a>
          </span>
        </a-table>
      </div>
    </div>
    <!-- 拼团用户 -->
    <a-drawer
      class="drawer-user"
      title="团单用户"
      :width="520"
      :visible="user.show"
      @close="user.show = false"
    >
      <a-table
        :columns="columnsForUser"
        :data-source="user.list"
        :pagination="false"
      >
        <span slot="avatarUrl" slot-scope="img">
          <img :src="img" class="product-img" />
        </span>
        <span slot="createdAt" slot-scope="time">{{ time | formatDate }}</span>
      </a-table>
    </a-drawer>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const PinSvc = require("@/service/pin");

const PIN_STATUS = {
  0: "待成团",
  1: "拼团中",
  9: "拼团成功"
};

export default {
  name: "PinOrderList",

  data() {
    return {
      pinId: 0,
      status: 0,

      list: [],
      filters: {},
      pagination: {
        showTotal: total => `共 ${total} 条记录`
      },
      loading: false,

      // 团单用户
      user: {
        show: false,
        list: []
      }
    };
  },

  computed: {
    columns() {
      return [
        {
          title: "图片",
          align: "center",
          dataIndex: "img",
          key: "img",
          width: 60,
          scopedSlots: { customRender: "img" }
        },
        {
          title: "标题",
          dataIndex: "title",
          key: "title"
        },
        {
          title: "原价",
          align: "right",
          dataIndex: "price",
          key: "price",
          width: 100
        },
        {
          title: "拼团价",
          align: "right",
          dataIndex: "pinPrice",
          key: "pinPrice",
          width: 100
        },
        {
          title: "头像",
          align: "center",
          dataIndex: "avatarUrl",
          key: "avatarUrl",
          width: 160,
          scopedSlots: { customRender: "avatarUrl" }
        },
        {
          title: "团长",
          dataIndex: "nickName",
          key: "nickName",
          width: 160
        },
        {
          title: "开团时间",
          align: "center",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 160,
          scopedSlots: { customRender: "createdAt" }
        },
        {
          title: "成团人数",
          align: "right",
          dataIndex: "num",
          key: "num",
          width: 100
        },
        {
          title: "参团人数",
          align: "right",
          dataIndex: "current",
          key: "current",
          width: 100,
          scopedSlots: { customRender: "num" }
        },

        {
          title: "状态",
          align: "center",
          dataIndex: "status",
          key: "status",
          width: 100,
          customRender: text => `${PIN_STATUS[text]}`
        }
      ];
    },

    columnsForUser() {
      return [
        {
          title: "头像",
          align: "center",
          dataIndex: "avatarUrl",
          key: "avatarUrl",
          width: 80,
          scopedSlots: { customRender: "avatarUrl" }
        },
        {
          title: "昵称",
          dataIndex: "nickName",
          key: "nickName"
        },
        {
          title: "参团时间",
          align: "center",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 160,
          scopedSlots: { customRender: "createdAt" }
        }
      ];
    }
  },

  methods: {
    // 查看团单用户
    async showUserList(item) {
      const ok = await this.fetchUser(item);
      if (ok) {
        this.user.show = true;
      }
    },
    async fetchUser(item) {
      try {
        const { res } = await PinSvc.queryOrderUser({
          pinId: item.pinId,
          pinUserId: item.id
        });
        this.user.list = res;
        return true;
      } catch (err) {
        console.error(err);
        this.$message.error("加载团单用户出错，错误：" + err.message);
        return false;
      }
    },

    async handleTableChange(pagination, filters) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.filters = filters;

      const { pageSize, current: page } = pagination;
      await this.fetch({
        pageSize,
        page,
        ...filters
      });
    },

    async fetch(params = {}) {
      this.loading = true;

      if (this.pinId) {
        params.pinId = this.pinId;
      }

      try {
        const data = await PinSvc.queryOrder({
          page: 1,
          pageSize: 10,
          ...params
        });

        const pagination = {
          ...this.pagination,
          total: data.cnt,
          current: data.page
        };
        this.list = data.res;
        this.pagination = pagination;

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    let { pinId } = this.$route.query || {};
    if (pinId) {
      this.pinId = pinId;
    }

    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-pin-order-list {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }
  .table-wrap {
    background: #fff;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #fff;
    }

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }

  .product-img {
    width: 60px;
    max-height: 60px;
  }
}

.drawer-user .product-img {
  width: 60px;
  max-height: 60px;
}
</style>
